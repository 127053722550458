<template>
  <div>
    <div v-for="(file, i) in data.Data" :key="i" class="px-4 py-2 flex items-center">
      <div class="flex-1">
        <b-tag type="is-primary is-light" class="mr-4">{{ext(file.Name)}}</b-tag>
        <span>{{file.Name}}</span>
      </div>
      <div>
        <b-button icon-left="download" class="icon-button" @click="downloadFile(file.File, file.Name)">
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  methods: {
    ext (name) {
      return name.split('.').pop().toUpperCase()
    },
    downloadFile (url, name) {
      if (this.isCordova) {
        if (window.cordova.platformId === 'android') {
          this.downloadAndroid(url, name)
        } else {
          this.downloadIos(url, name)
        }
      } else {
        window.open(url, '_blank')
      }
    },
    downloadAndroid (url, name) {
        window.cordova.plugins.DownloadManager.download(url, name, 'Download file', () => {}, (error) => alert(error), { setDestinationInExternalPublicDir: true })
    },
    downloadIos (url, name) {
      const loading = this.$buefy.loading.open()

      const handleError = (type) => (err) => {
        loading.close()
        console.log('Error: ', type, dir, name, err.message ? err.message : JSON.stringify(err))
        alert('Failed to download file')
      }

      const dir = window.cordova.file.documentsDirectory
      window.resolveLocalFileSystemURL(dir, fs => {
        fs.getDirectory('Download', { create: true, exclusive: false }, dirEntry => {
            dirEntry.getFile(name, { create: true, exclusive: false }, fileEntry => {
                const handleBlob = (data) => {
                    loading.close()
                    fileEntry.createWriter((fileWriter) => {
                        fileWriter.onerror = (e) => {
                            console.log('Failed file write: ' + e.toString())
                        }
                        fileWriter.onwriteend = () => {
                            console.log('Successful file write...', name)
                            loading.close()
                            fileEntry.file((f) => {
                                window.cordova.plugins.fileOpener2.open(
                                    dir + 'Download/' + name,
                                    f.type
                                )
                            }, handleError('file'))
                        }
                        fileWriter.write(data)
                    })
                }
                const xhr = new XMLHttpRequest()
                xhr.open('GET', url, true)
                xhr.responseType = 'blob'

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400) {
                        handleBlob(xhr.response)
                    } else if (xhr.status >= 400) {
                        console.log('Error download file')
                        handleError('fetch')(new Error(xhr.responseText))
                    }
                }

                xhr.send()
            }, handleError('getFile'))
        }, handleError('getDirectory'))
      }, handleError('resolveLocalFileSystemURL'))
    }
  }
}
</script>

<style>

</style>
